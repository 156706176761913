.selected_works {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
}

.breadcrumb {
  cursor: pointer;
}

.inactive {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: #7a7a7a;
}

.active {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: var(--text);
}

.section_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_container {
  display: flex;
}

.main {
  font-size: 4rem;
  font-weight: bold;
  margin: 0.25em 0;
}

.projects {
  display: grid;
  gap: 5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 16px 0px;
  height: auto;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.project::after {
  content: '';
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 30px 20px var(--shadow);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.project:hover::after {
  opacity: 1;
  transform: scale(1.05);
}

.project_screenshot {
  width: 100%;
  height: auto;
}

.project_content {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.project_type {
  padding: 8px 12px;
  color: var(--main);
  font-size: 1.5rem;
  background-color: var(--text);
  margin: 0.5rem 0;
  width: max-content;
  border-radius: 20px;
}

.project_name {
  font-size: 2.125rem;
  margin-top: 0.5em;
  font-weight: bold;
}

.project_desc {
  font-family: 'Inter';
  font-size: 1.75rem;
  margin: 0.875em 0;
}

.project_link {
  font-family: 'Inter';
  font-size: 1.75rem;
  color: var(--project_link);
  font-weight: bold;
  width: 175px;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.project_link svg {
  width: 46px;
  height: 50px;
  padding: 6px 0;
  margin-left: 1.5rem;
  margin-top: 0;
}

.project_link path {
  fill: var(--text);
}

.right {
  display: inline-block;
  border: solid;
  border-color: var(--project_link);
  transform: rotate(-45deg);
  border-width: 0 6px 6px 0;
  padding: 3px;
  margin-left: 8px;
}

@media screen and (min-width: 801px) {
  .main {
    font-size: 5.5rem;
    font-weight: bold;
    margin: 0.25em 0;
  }

  .projects {
    flex-direction: row;
    justify-content: space-between;
  }
}
