.post_wrapper {
  width: 100%;
  position: absolute;
}

.post {
  margin: auto;
  max-width: 650px;
}

.breadcrumb {
  margin-bottom: 3.5rem;
}

.inactive {
  cursor: pointer;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: #7a7a7a;
}

.active {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: var(--text);
}

.featured_image {
  width: 100%;
  object-fit: contain;
}

.featured_image_credit {
  font-size: 1.5rem;
  margin: 0.5em 0 2.5em;
  text-align: center;
}

figcaption a {
  font-size: 1.5rem;
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.post_content {
  margin: 2rem 0;
}

.post_content h1 {
  font-size: 2.875rem;
  font-weight: bold;
  margin: 0.875em 0;
}

.post_content h2 {
  font-size: 2.75rem;
  font-weight: bold;
  margin: 0.75em 0;
}

.post_content h3 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0.5em 0;
}

.post_content h4 {
  font-size: 2.125rem;
  font-weight: bold;
  margin: 0.5em 0;
}

.post_content img {
  max-width: 600px;
  height: auto;
  margin: 2.5rem 0;
  display: block;
}

.post_content strong {
  font-size: 1.875rem;
  margin: 1em 0;
  font-weight: bold;
  line-height: 2.5rem;
  font-family: 'Inter';
}

.post_content p {
  font-size: 1.875rem;
  margin: 1em 0;
  line-height: 2.5rem;
  font-family: 'Inter';
}

.post_content em {
  font-size: 1.875rem;
  margin: 1em 0;
  line-height: 2.5rem;
  font-family: 'Inter';
}

.post_content a {
  font-size: 1.875rem;
}

.share_post {
  margin: 4rem 0 3rem;
}

.share_post_text {
  font-size: 2.125rem;
  font-weight: bold;
  color: var(--project_link);
}

.share_post svg {
  width: 24px;
  height: 24px;
  margin: 0 0 -4px 8px;
  stroke-width: 1.5px;
  stroke: var(--project_link);
}

.next_post {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 4rem;
}

.next_post_title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5em 0;
  color: var(--project_link);
}

.next {
  font-size: 1.75rem;
  color: var(--project_link);
  font-weight: bold;
  background: none;
  border: none;
  display: flex;
  align-items: center;
}

.next svg {
  width: 46px;
  height: 50px;
  padding: 6px 0;
  margin-left: 1.5rem;
  margin-top: 0;
}

.next path {
  fill: var(--project_link);
}
