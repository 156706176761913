.wrapper {
  max-width: 1140px;
  margin: auto;
}

.container {
  min-height: 100vh;
  width: 100%;
  padding: 2rem 2rem 0;
  background: var(--main);
  color: var(--text);
}
