*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --first: #f2994a;
  --second: #bdbdbd;
  --text: #1a181b;
  --main: #ffffff;
  --content: #3e3d3d;
  --project_link: #1a181b;
  --nav: #f2994a;
  --shadow: #dfe6e9;
}

::selection {
  background-color: #f2994a;
  color: var(--main);
}

::-moz-selection {
  background-color: #f2994a;
  color: var(--main);
}

* {
  margin: 0;
  font: 62.5% 'Futura', sans-serif;
  transition: background cubic-bezier(0.49, 0.11, 0.6, 1) 1.5s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 1.5rem;
}

main {
  margin-top: 4rem;
  padding-bottom: 5rem;
  position: relative;
  width: auto;
}

svg {
  width: 46px;
  height: 40px;
  margin-top: -12px;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

.wrap {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #1a181b;
  z-index: 2;
}

button:focus,
a:focus {
  outline: 2px solid orange;
}

a {
  text-decoration: none;
}

a:active {
  outline: none;
}

@media screen and (max-width: 801px) {
  .cursor {
    display: none;
  }
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  background-color: green;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) width;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  mix-blend-mode: difference;
}

pre {
  padding: 2rem !important;
  /* font-size: 1.875rem; */
  margin: 2rem 0;
  border-radius: 8px;
  overflow: auto;
}

pre span {
  font-size: inherit;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

small {
  font-size: 1.5rem;
  margin: 3rem 0;
}

li {
  font-size: 1.875rem;
  margin: 2rem 0;
}
