.contact {
  display: flex;
  flex-direction: column;
}

.breadcrumb {
  cursor: pointer;
  margin-bottom: 2rem;
}

.inactive {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: #7a7a7a;
}

.active {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: var(--text);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 5.875rem;
}

.image {
  display: flex;
  max-width: 450px;
}

img {
  width: 100%;
  height: auto;
}

.contact_content {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 450px;
  margin-top: 3rem;
}

.heading {
  font-size: 2.875rem;
  font-weight: bold;
  position: relative;
  color: var(--project_link);
  font-weight: bold;
}

.contact_bait {
  font-family: 'Inter';
  font-size: 1.875rem;
  color: var(--content);
  margin: 0.5em 0;
  line-height: 30px;
  margin: 2rem 0;
}

.contact_options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.contact_option {
  margin-bottom: 2rem;
}

.contact_option:first-child {
  margin-right: 2rem;
}

.contact_option > a {
  font-family: 'Inter';
  font-size: 1.875rem;
  color: var(--contact_option);
  line-height: 24px;
  text-decoration: underline;
}

.contact_option > p {
  color: #f2994a;
  font-weight: bold;
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 801px) {
  .main {
    flex-direction: row;
    align-items: normal;
  }

  .image {
    display: flex;
    margin-right: 9rem;
  }
}
